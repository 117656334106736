import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { BoldItalicUnderlineToggles, MDXEditor, toolbarPlugin } from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FieldArrayWithId, UseFormReturn, useFieldArray } from 'react-hook-form';
import Select from 'react-select';

import { useForm } from 'hooks';
import { FormMethodStep, UpdateableMethodStep } from 'types';

import FallbackImg from 'components/FallbackImg';
import LabelCalculation from 'components/LabelCalculation';

import ConfirmationModal from 'components/ConfirmationModal';
import LabelTextArea from 'components/LabelTextArea';
import Prompt from 'components/Prompt';
import { useDispatch } from 'hooks';
import { convertMethod } from 'redux/actions/recipe';
import { AiIcon, BsIcon, HiIcon, MdIcon } from 'theme/icon';
import buildAssetString from 'utils/buildAssetString';

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

type TextChunk = { text: string; bold?: boolean };

type MeasurementSystem = 'si' | 'imperial';

// Helper function to convert a number to a string with a fixed number of decimal places.
function toFixedIfNecessary(num: number, digits: number): string {
  const fixedStr = num.toFixed(digits);
  // Check if the resulting string ends with '.0' and remove it
  return fixedStr.endsWith('.0') ? fixedStr.substring(0, fixedStr.length - 2) : fixedStr;
}

// Helper function to find the closest fraction to a given number - return the matching ascii character
function findMatchingFraction(convertedQuantity: number): string {
  const fractions = [
    { value: 0.125, string: '⅛' },
    { value: 0.25, string: '¼' },
    { value: 0.375, string: '⅜' },
    { value: 0.5, string: '½' },
    { value: 0.625, string: '⅝' },
    { value: 0.75, string: '¾' },
    { value: 0.875, string: '⅞' },
  ];

  const fraction = fractions.reduce((prev, curr) => {
    return Math.abs(curr.value - convertedQuantity) < Math.abs(prev.value - convertedQuantity)
      ? curr
      : prev;
  });

  return fraction.string;
}

// Helper function to convert the recipe method text into an array
// of chunks to be rendered by the markdown editor.
function convertToChunks(input: string, scale: number, unitSystem: MeasurementSystem): TextChunk[] {
  const chunksWihtValues: TextChunk[] = [];
  // Regex to capture the value, unit (if any) and name (if any).
  const regexValues = /\{\{value:(\d+(\.\d+)?)(\|unit:([^}]+))?(\|name:([^}]+))?\}\}/g;

  let lastIndex = 0;
  input.replace(
    regexValues,
    (match, quantity, decimalComponent, unitWithPipe, unit, nameWithPipe, name, index) => {
      // Add the text before the match, handling bold if needed
      if (index > lastIndex) {
        chunksWihtValues.push({ text: input.substring(lastIndex, index) });
      }

      lastIndex = index + match.length;

      let convertedQuantity = parseFloat(quantity) * scale;
      let convertedUnit = unit;

      // Convert units for 'g' and 'ml' if unit system is 'imperial'
      if (unitSystem === 'imperial') {
        if (unit === 'g') {
          convertedQuantity *= 0.035; // Convert grams to ounces
          convertedUnit = 'oz';
        } else if (unit === 'ml') {
          convertedQuantity *= 0.0338; // Convert ml to fluid ounces
          convertedUnit = 'floz';
        }
      }

      if (name) {
        // Check if quantity is greater than 1. Replace (s) and (es) with empty string
        // if quantity is less than 1, otherwise replace with 's' or 'es'.
        const formattedString = name
          .replace('(s)', convertedQuantity > 1 ? 's' : '')
          .replace('(es)', convertedQuantity > 1 ? 'es' : '');

        chunksWihtValues.push({ text: formattedString, bold: true });
        return match;
      }

      const tspOrTbsp = convertedUnit === 'tsp' || convertedUnit === 'tbsp';
      const absoluteMeasurement = !convertedUnit || tspOrTbsp;

      // If there is no unit or unit is tsp/tbs, and if the quantity is less than 1, replace the quantity with
      // the relevant ascii character matching the closest fraction (e.g. 1/2, 1/4, 3/4, 1/3, 2/3)
      const formattedString =
        convertedQuantity < 1 && absoluteMeasurement
          ? findMatchingFraction(convertedQuantity)
          : toFixedIfNecessary(
              convertedQuantity,
              !absoluteMeasurement && unitSystem === 'si' && convertedQuantity > 1 ? 0 : 1,
            );

      // Add a space between the quantity and the unit for tsp/tbsp
      const formattedQuantity = `${formattedString}${tspOrTbsp ? ' ' : ''}${convertedUnit || ''}`;

      chunksWihtValues.push({ text: formattedQuantity, bold: true });
      return match;
    },
  );

  if (lastIndex < input.length) {
    chunksWihtValues.push({ text: input.substring(lastIndex) });
  }

  // Regex to catpure value_not_scaled + unit
  const regexValueNotScaled =
    /\{\{value_not_scaled:(\d+(\.\d+)?)(\|unit:([^}]+))?(\|name:([^}]+))?\}\}/g;

  const chunksWihtValuesNotScaled: TextChunk[] = [];
  chunksWihtValues.forEach(chunk => {
    if (chunk.bold) {
      // Already bold - no need to process further
      chunksWihtValuesNotScaled.push(chunk);
      return;
    }

    const { text } = chunk;

    let lastIndex = 0;
    text.replace(
      regexValueNotScaled,
      (match, quantity, decimalComponent, unitWithPipe, unit, nameWithPipe, name, index) => {
        // Add the text before the match, handling bold if needed
        if (index > lastIndex) {
          chunksWihtValuesNotScaled.push({ text: text.substring(lastIndex, index) });
        }

        lastIndex = index + match.length;

        const convertedQuantity = parseFloat(quantity);
        let convertedUnit = unit;

        // Convert units for 'g' and 'ml' if unit system is 'imperial'
        if (unitSystem === 'imperial') {
          if (unit === 'g') {
            convertedUnit = 'oz';
          } else if (unit === 'ml') {
            convertedUnit = 'floz';
          }
        }

        if (name) {
          // Check if quantity is greater than 1. Replace (s) and (es) with empty string
          // if quantity is less than 1, otherwise replace with 's' or 'es'.
          const formattedString = name
            .replace('(s)', convertedQuantity > 1 ? 's' : '')
            .replace('(es)', convertedQuantity > 1 ? 'es' : '');

          chunksWihtValuesNotScaled.push({ text: formattedString, bold: true });
          return match;
        }

        const tspOrTbsp = convertedUnit === 'tsp' || convertedUnit === 'tbsp';
        const absoluteMeasurement = !convertedUnit || tspOrTbsp;

        // If there is no unit or unit is tsp/tbs, and if the quantity is less than 1, replace the quantity with
        // the relevant ascii character matching the closest fraction (e.g. 1/2, 1/4, 3/4, 1/3, 2/3)
        const formattedString =
          convertedQuantity < 1 && absoluteMeasurement
            ? findMatchingFraction(convertedQuantity)
            : toFixedIfNecessary(
                convertedQuantity,
                !absoluteMeasurement && unitSystem === 'si' && convertedQuantity > 1 ? 0 : 1,
              );

        // Add a space between the quantity and the unit for tsp/tbsp
        const formattedQuantity = `${formattedString}${tspOrTbsp ? ' ' : ''}${convertedUnit || ''}`;

        chunksWihtValuesNotScaled.push({ text: formattedQuantity, bold: true });
        return match;
      },
    );

    if (lastIndex < text.length) {
      chunksWihtValuesNotScaled.push({ text: text.substring(lastIndex) });
    }
  });

  // Regex to capture the bold text.
  const regexStars = /\*\*(.*?)\*\*/g;
  const chunksWithBold: TextChunk[] = [];

  chunksWihtValuesNotScaled.forEach(chunk => {
    if (chunk.bold) {
      // Already bold - no need to process further
      chunksWithBold.push(chunk);
      return;
    }

    // Check for the **bold** pattern using regexStars and string replace
    const boldChunks = chunk.text.split(regexStars);
    boldChunks.forEach((text, idx) => {
      chunksWithBold.push({ text, bold: idx % 2 !== 0 });
    });
  });

  return chunksWithBold;
}

function chunksToMarkdown(chunks: TextChunk[]): string {
  return chunks
    .map(chunk => {
      return chunk.bold ? `**${chunk.text}**` : chunk.text;
    })
    .join('');
}

const getMethodBgColour = (recipeCode: string, isPrimaryPortion: boolean) => {
  if (recipeCode.includes('-V')) {
    return isPrimaryPortion ? 'blue.100' : 'green.100';
  }

  return isPrimaryPortion ? 'orange.100' : 'yellow.100';
};

function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef(value);
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

// Function for converting the image and audio urls to their file names
const convertUrlToName = (url?: string | null) =>
  url ? decodeURIComponent(url.split('/')[url.split('/').length - 1]) : null;

export const buildLabelCalculationProps = (url?: string | null) => {
  const conversionValue = convertUrlToName(url);
  return {
    status: conversionValue?.length ? 'success' : 'warning',
    tooltipText: conversionValue?.length
      ? 'This asset has been uploaded'
      : 'This asset still requires uploading',
    tooltipBgColor: conversionValue?.length ? 'green.400' : 'yellow.400',
  };
};

interface FormState {
  recipeMethod: FormMethodStep[];
}

interface OptionType {
  label: string;
  value: number;
}

interface RecipeMethodFormProps {
  /**
   * Default values for the form
   */
  recipeMethod: FormMethodStep[];
  /**
   * Overall form title
   */
  title: string;
  /**
   * List of equipment to be used as select options
   */
  equipmentOptions: OptionType[];
  /**
   * The recipe code used to generate asset string names
   */
  recipeCode: string;
  /**
   * Force react-hook-form to reset displayed data if this changes
   */
  portionSize: number;
  /**
   * Loading state controls
   */
  isSubmitting: boolean;
  isLoading: boolean;
  isPrimaryPortion: boolean;
  isYoutubeRecipe: boolean;
  /**
   * Action handlers
   */
  onSubmit: (values: UpdateableMethodStep[]) => Promise<boolean>;
  onDeleteStep: (stepId: number) => void;
  onDeleteAllSteps: () => Promise<void>;
}

const TimerField: React.FC<
  UseFormReturn<FormState> & {
    recipeCode: string;
    equipmentOptions: OptionType[];
    stepNumber: number;
    stepTitle: string;
    // Field Props
    nestIndex: number;
    item: FieldArrayWithId<FormState, 'recipeMethod.0.tasks', 'uuid'>;
    index: number;
  }
> = ({
  recipeCode,
  stepNumber,
  stepTitle,
  item,
  index,
  nestIndex,
  register,
  setValue,
  unregister,
}) => {
  /**
   * With the task timer capable of being added/removed dynamically via a checkbox, we run into
   * issues with dynamically registering/unregistering react-hook-form controlled components
   * and maintaining a correct form state.
   *
   * As such, we opt for using 'dumb' input components for the timer fields, registering them
   * on mount, and ensuring that react-hook-form is kept up to date with input changes through
   * onChange handlers mapped to setValue callbacks.
   *
   * The key to this working overall is by setting the value of `timer` on mount to an object
   * and to setting it to `null` on unmount.
   */

  useEffect(() => {
    const timer = {
      id: item.timer?.id,
      instruction: item.timer?.instruction || '',
      duration: item.timer?.duration || 0,
      title: stepTitle,
      endAudio: null,
    };

    const timerString = `recipeMethod.${nestIndex}.tasks.${index}.timer` as const;
    const timerTitleString = `recipeMethod.${nestIndex}.tasks.${index}.timer.title` as const;
    const timerDurationString = `recipeMethod.${nestIndex}.tasks.${index}.timer.duration` as const;
    const timerInstructionsString =
      `recipeMethod.${nestIndex}.tasks.${index}.timer.instruction` as const;
    const timerIdString = timer.id
      ? (`recipeMethod.${nestIndex}.tasks.${index}.timer.id` as const)
      : null;

    // On mount, set the overall timer value based on the default timer object created above.
    setValue(timerString, timer);
    // Auto set the timer title to be the step title
    setValue(timerTitleString, stepTitle);

    // Register all of the timer form inputs which have a corresponding `<Input>` component

    if (timerIdString) register(timerIdString);

    register(timerDurationString, { valueAsNumber: true, required: true });
    register(timerTitleString, { required: true });
    register(timerInstructionsString);

    return () => {
      if (timerIdString) unregister(timerIdString);

      unregister(timerDurationString);
      unregister(timerTitleString);
      unregister(timerInstructionsString);

      // @ts-ignore
      setValue(timerString, null, { shouldDirty: true });
    };
  }, [register, unregister]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { currentTarget } = e;
    // @ts-ignore
    setValue(currentTarget.name, currentTarget.value, { shouldDirty: true });
  };

  const handleTimerDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    /**
     * Seperate handler for the timer duration as we need to convert from minutes
     * to seconds
     */
    const { currentTarget } = e;
    const durationValue = e.currentTarget.value;
    const convertedDuration = durationValue.length ? (parseInt(durationValue) * 60).toString() : 0;
    // @ts-ignore
    setValue(currentTarget.name, convertedDuration, { shouldDirty: true });
  };

  // Extract the timer end audio file name, if it exists. Otherwise generate
  // the expected timer end audio file name
  const timerAudio = buildAssetString({
    recipeCode,
    stepNumber,
    portion: 2,
    taskNumber: item.taskNumber,
    suffix: '.mp3',
    isTimer: true,
    isEnd: true,
  });

  return (
    <Tr
      css={`
        td:first-of-type {
          padding-left: 0;
        }
        td:last-child {
          padding-right: 0;
        }
        td {
          vertical-align: top;
        }
      `}
    >
      <Td>
        {item.timer?.id && (
          <Input
            type="hidden"
            name={`recipeMethod.${nestIndex}.tasks.${index}.timer.id`}
            defaultValue={item.timer?.id}
          />
        )}
        <FormControl isRequired>
          <FormLabel>Timer Duration</FormLabel>
          <Input
            type="text"
            name={`recipeMethod.${nestIndex}.tasks.${index}.timer.duration` as const}
            defaultValue={item.timer?.duration ? item.timer?.duration / 60 : ''}
            placeholder={'Duration (Minutes)'}
            bgColor="white"
            onChange={handleTimerDurationChange}
          />
          <FormHelperText>Duration in minutes</FormHelperText>
        </FormControl>
      </Td>
      <Td>
        <LabelCalculation
          cursor="pointer"
          fontSize="sm"
          px="sm"
          label="Timer Audio"
          value={timerAudio}
          onClick={() =>
            item?.timer?.endAudio ? window.open(item.timer.endAudio, '_blank') : null
          }
          {...buildLabelCalculationProps(item?.timer?.endAudio)}
        />
      </Td>
      <Td>
        <FormControl>
          <FormLabel>Timer Instruction</FormLabel>
          <Textarea
            name={`recipeMethod.${nestIndex}.tasks.${index}.timer.instruction` as const}
            defaultValue={item.timer?.instruction || ''}
            placeholder={'Instruction'}
            bgColor="white"
            onChange={handleChange}
          />
        </FormControl>
      </Td>
    </Tr>
  );
};

const StepTaskField: React.FC<
  UseFormReturn<FormState> & {
    recipeCode: string;
    equipmentOptions: OptionType[];
    stepNumber: number;
    stepTitle: string;
    // Field Props
    nestIndex: number;
    item: FieldArrayWithId<FormState, 'recipeMethod.0.tasks', 'uuid'>;
    index: number;
    portionSizePreview: number;
    measurementSystem: MeasurementSystem;
    viewScalableMethod: boolean;
  }
> = props => {
  const {
    recipeCode,
    stepNumber,
    nestIndex,
    item,
    index,
    register,
    watch,
    formState,
    portionSizePreview,
    measurementSystem,
    viewScalableMethod,
  } = props;
  const mdxEditorScalableRef = useRef<typeof MDXEditor>(null);
  const mdxEditorDefaultRef = useRef<typeof MDXEditor>(null);

  const [hasTimer, setHasTimer] = useState(Boolean(item?.timer));

  // Extract our form errors
  const { errors } = formState;

  // If a timer has been added, watch the duration so that we can use it to
  // build a correct asset name
  const watchTimerDuration = watch(
    `recipeMethod.${nestIndex}.tasks.${index}.timer.duration` as const,
  ) as number | undefined;

  // Extract the task audio file name, if it exists. Otherwise generate
  // the expected task audio file name

  const taskAudio = buildAssetString({
    recipeCode,
    stepNumber,
    portion: 2,
    taskNumber: item.taskNumber,
    suffix: '.mp3',
    isTimer: hasTimer,
    timerDuration: watchTimerDuration
      ? watchTimerDuration / 60
      : item.timer
      ? item.timer.duration / 60
      : 0,
  });

  const instructionsAreaProps = register(
    `recipeMethod.${nestIndex}.tasks.${index}.instructions` as const,
    { required: 'Please enter task instructions' },
  );
  const methodAreaProps = register(`recipeMethod.${nestIndex}.tasks.${index}.method` as const);

  const [scalableValue, setScalableValue] = useState<string>(item.instructions);
  const [defaultValue, setDefaultValue] = useState<string>(item.method);

  // @ts-ignore - TODO: Fix this
  if (mdxEditorDefaultRef?.current?.setMarkdown) {
    // @ts-ignore - TODO: Fix this
    mdxEditorDefaultRef?.current?.setMarkdown(
      chunksToMarkdown(
        convertToChunks(
          defaultValue.replace(/\\_/g, '_'),
          portionSizePreview / 2,
          measurementSystem,
        ),
      ),
    );
  }

  // @ts-ignore - TODO: Fix this
  if (mdxEditorScalableRef?.current?.setMarkdown) {
    // @ts-ignore - TODO: Fix this
    mdxEditorScalableRef?.current?.setMarkdown(
      chunksToMarkdown(
        convertToChunks(
          scalableValue.replace(/\\_/g, '_'),
          portionSizePreview / 2,
          measurementSystem,
        ),
      ),
    );
  }

  return (
    <React.Fragment key={item.uuid}>
      <Tr
        css={`
          td:first-of-type {
            padding-left: 0;
          }
          td:last-child {
            padding-right: 0;
          }
          td {
            vertical-align: top;
          }
        `}
      >
        <Td colSpan={3}>
          <FormControl
            isInvalid={
              viewScalableMethod
                ? Boolean(errors.recipeMethod?.[nestIndex]?.tasks?.[index]?.instructions)
                : Boolean(errors.recipeMethod?.[nestIndex]?.tasks?.[index]?.method)
            }
            isRequired
          >
            {item.id && (
              <Input
                type="hidden"
                {...register(`recipeMethod.${nestIndex}.tasks.${index}.id` as const)}
                value={item.id}
              />
            )}
            <Input
              type="hidden"
              {...register(`recipeMethod.${nestIndex}.tasks.${index}.taskNumber` as const)}
              value={item.taskNumber || undefined}
            />
            <Flex direction="row" alignItems="center" justifyContent="flex-start" mb="5">
              <Text fontSize="md" mr="10">
                Task {item.taskNumber || undefined}:
              </Text>
              <Box width="50%">
                <LabelCalculation
                  cursor="pointer"
                  fontSize="sm"
                  px="sm"
                  value={taskAudio}
                  onClick={() => (item.audio ? window.open(item.audio, '_blank') : null)}
                  {...buildLabelCalculationProps(item.audio)}
                />
              </Box>
            </Flex>
            {item.timer && (
              <Input
                type="hidden"
                {...register(`recipeMethod.${nestIndex}.tasks.${index}.timer` as const)}
                value={item.timer ? '' : undefined}
              />
            )}
            <>
              <Flex
                backgroundColor="white"
                mb="sm"
                flexDir="column"
                display={viewScalableMethod ? 'flex' : 'none'}
              >
                <MDXEditor
                  className="mdx-editor"
                  markdown={scalableValue.replace(/\\_/g, '_')}
                  onChange={val => {
                    const replacedVal = val.replace(/\\_/g, '_');
                    instructionsAreaProps.onChange({
                      target: { value: replacedVal, name: instructionsAreaProps.name },
                    });
                    setScalableValue(replacedVal);
                  }}
                  plugins={[
                    toolbarPlugin({
                      toolbarContents: () => <BoldItalicUnderlineToggles />,
                    }),
                  ]}
                />
              </Flex>
              <Flex mb="lg" flexDir="column" display={viewScalableMethod ? 'flex' : 'none'}>
                <Text fontWeight="bold" mt="sm" mb="xs">
                  Preview
                </Text>
                <Flex backgroundColor="white">
                  <Flex mb="sm" flexDir="column" position="relative">
                    <Flex position="absolute" top={0} bottom={0} left={0} right={0} zIndex={1} />
                    {/* @ts-ignore - TODO: Fix this */}
                    <MDXEditor
                      // @ts-ignore - TODO: Fix this
                      ref={mdxEditorScalableRef}
                      className="mdx-editor"
                      markdown={chunksToMarkdown(
                        convertToChunks(
                          scalableValue.replace(/\\_/g, '_'),
                          portionSizePreview / 2,
                          measurementSystem,
                        ),
                      )}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </>
            <>
              <Flex
                backgroundColor="white"
                mb="sm"
                flexDir="column"
                display={!viewScalableMethod ? 'flex' : 'none'}
              >
                <MDXEditor
                  className="mdx-editor"
                  markdown={defaultValue.replace(/\\_/g, '_')}
                  onChange={val => {
                    const replacedVal = val.replace(/\\_/g, '_');
                    methodAreaProps.onChange({
                      target: { value: replacedVal, name: methodAreaProps.name },
                    });
                    setDefaultValue(replacedVal);
                  }}
                  plugins={[
                    toolbarPlugin({
                      toolbarContents: () => <BoldItalicUnderlineToggles />,
                    }),
                  ]}
                />
              </Flex>
              <Flex mb="lg" flexDir="column" display={!viewScalableMethod ? 'flex' : 'none'}>
                <Text fontWeight="bold" mt="sm" mb="xs">
                  Preview
                </Text>
                <Flex backgroundColor="white">
                  <Flex mb="sm" flexDir="column" position="relative">
                    <Flex position="absolute" top={0} bottom={0} left={0} right={0} zIndex={1} />
                    {/* @ts-ignore - TODO: Fix this */}
                    <MDXEditor
                      //  @ts-ignore - TODO: Fix this
                      ref={mdxEditorDefaultRef}
                      className="mdx-editor"
                      markdown={chunksToMarkdown(
                        convertToChunks(
                          defaultValue.replace(/\\_/g, '_'),
                          portionSizePreview / 2,
                          measurementSystem,
                        ),
                      )}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </>
            <FormErrorMessage>
              {viewScalableMethod
                ? errors.recipeMethod?.[nestIndex]?.tasks?.[index]?.instructions?.message
                : errors.recipeMethod?.[nestIndex]?.tasks?.[index]?.method?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl my="xs">
            <Checkbox
              isChecked={hasTimer}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const {
                  target: { checked },
                } = event;
                setHasTimer(checked);
              }}
            >
              Task has timer?
            </Checkbox>
          </FormControl>
        </Td>

        {/* <Td>
          <Controller
            render={({ field: { onChange, onBlur, value } }) => {
              const option = equipmentOptions.find((opt) => opt.value === value);
              return (
                <Select
                  options={equipmentOptions}
                  onChange={(
                    value: ValueType<OptionType, false>,
                    // Declare unused action param to satisfy react-select typings
                    action: ActionMeta<OptionType>
                  ) => {
                    onChange(value?.value);
                  }}
                  onBlur={onBlur}
                  // Select expects an `OptionType` but we want to store the data
                  // within react-hook-forms as a number - see `onChange` above which
                  // takes the `OptionType` value and only passes on the value to
                  // react-hook-form.
                  //
                  // This conditional is purely for the initialisation of the `defaultValue`.
                  // We set the `defaultValue` as a number which needs turning into an
                  // `OptionType` when passed to the Select component on load.
                  value={typeof value === "number" ? option || defaultEquipmentOption : value}
                />
              );
            }}
            name={`recipeMethod.${nestIndex}.tasks.${index}.equipment` as const}
            control={control}
            defaultValue={defaultEquipmentOption?.value}
          />
        </Td> */}
      </Tr>
      {hasTimer ? <TimerField {...props} /> : null}
    </React.Fragment>
  );
};

const StepTaskFieldArray: React.FC<
  UseFormReturn<FormState> & {
    recipeCode: string;
    portionSize: number;
    equipmentOptions: OptionType[];
    stepNumber: number;
    stepTitle: string;
    portionSizePreview: number;
    measurementSystem: MeasurementSystem;
    viewScalableMethod: boolean;
    // Field Props
    nestIndex: number;
  }
> = props => {
  const { portionSize, nestIndex, control, viewScalableMethod } = props;

  const { fields, remove, append } = useFieldArray({
    control,
    // The following casting is to keep TS happy
    name: `recipeMethod.${nestIndex}.tasks` as 'recipeMethod.0.tasks',
    keyName: 'uuid',
  });

  return (
    <>
      <Table flex={1} size="sm">
        <Thead flex={1}>
          <Tr
            css={`
              td:first-of-type {
                padding-left: 0;
              }
              td:last-child {
                padding-right: 0;
              }
            `}
          >
            <Td colSpan={2} fontWeight="bold" width="300px">
              Step Tasks
            </Td>
          </Tr>
        </Thead>
        <Tbody>
          {fields.map((item, k) => {
            return (
              <StepTaskField
                key={`step-task-${item.uuid}`}
                item={{ ...item, numPeople: portionSize }}
                index={k}
                {...props}
              />
            );
          })}
        </Tbody>
      </Table>
      <HStack flex={1}>
        <Button
          onClick={() =>
            append({
              method: '',
              instructions: '',
              taskNumber: fields.length + 1,
              numPeople: portionSize,
            })
          }
          leftIcon={<BsIcon name="PlusSquareFill" color="blue.500" />}
          variant="ghost"
        >
          Add task
        </Button>
        {fields.length ? (
          <Button
            onClick={() => remove(fields.length - 1)}
            leftIcon={<AiIcon name="FillMinusSquare" color="red.500" />}
            variant="ghost"
          >
            Remove task
          </Button>
        ) : null}
      </HStack>
    </>
  );
};

const MethodStepField: React.FC<
  UseFormReturn<FormState> & {
    isPrimaryPortion: boolean;
    recipeCode: string;
    portionSize: number;
    equipmentOptions: OptionType[];
    // Field Props
    item: FieldArrayWithId<FormState, 'recipeMethod', 'uuid'>;
    index: number;
    removeStep: (id: number | null | undefined, index: number) => Promise<void>;
    portionSizePreview: number;
    measurementSystem: MeasurementSystem;
    viewScalableMethod: boolean;
  }
> = ({
  isPrimaryPortion,
  recipeCode,
  portionSize,
  equipmentOptions,
  item,
  index,
  removeStep,
  portionSizePreview,
  measurementSystem,
  viewScalableMethod,
  ...useFormValues
}) => {
  const {
    register,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useFormValues;
  const { defaultValuesRef } = control;

  const [isOpen, setIsOpen] = useState(false);

  // Calculate the step header photo value to display
  const headerPhoto = buildAssetString({
    recipeCode,
    stepNumber: item.stepNumber,
    suffix: '.jpg',
  });

  /**
   * This method helps us to find duplicate step numbers and step numbers  which are out
   * of range or not. If the step number is valid, returns `true` otherwise return the
   * error message
   */
  const validateStepNumber = (value: number) => {
    const allStepNumbers = getValues().recipeMethod.map(method => method.stepNumber);
    const isDuplicateStepNumber = allStepNumbers.filter(x => x == value).length !== 1;
    if (isDuplicateStepNumber) {
      return `Duplicate step number. The step number ${value} has already been used`;
    } else if (value < 1 || value > allStepNumbers.length) {
      // Check that the provided step number is in a valid range
      return `Step number should be between 1 and ${allStepNumbers.length}`;
    }
    return true;
  };

  return (
    <Box bg={getMethodBgColour(recipeCode, isPrimaryPortion)} borderRadius="md" padding="sm">
      <Flex mb={'sm'}>
        <Heading as="h4" size="md" mb={0} alignSelf={'center'} mr={3}>
          STEP
        </Heading>
        <FormControl isInvalid={Boolean(errors?.recipeMethod?.[index]?.stepNumber?.message)}>
          <Input
            width="unset"
            type="number"
            onWheel={event => event.currentTarget.blur()}
            {...register(`recipeMethod.${index}.stepNumber` as const, {
              validate: validateStepNumber,
              required: true,
              valueAsNumber: true,
            })}
            errorBorderColor="red.300"
            bgColor="white"
            defaultValue={item.stepNumber || undefined}
          />
          <FormErrorMessage>{errors?.recipeMethod?.[index]?.stepNumber?.message}</FormErrorMessage>
        </FormControl>
      </Flex>
      {item.id && (
        <Input type="hidden" {...register(`recipeMethod.${index}.id` as const)} value={item.id} />
      )}

      <HStack spacing="md" mb="sm">
        <Box width="30%">
          <Input
            {...register(`recipeMethod.${index}.title` as const)}
            type="text"
            placeholder="Step Title"
            bgColor="white"
            defaultValue={item.title || ''}
          />
        </Box>
        <Box>
          <LabelCalculation
            cursor="pointer"
            px="md"
            value={headerPhoto}
            {...buildLabelCalculationProps(item.headerPhoto)}
            onClick={() => (item.headerPhoto ? setIsOpen(true) : null)}
          />
        </Box>
      </HStack>
      <StepTaskFieldArray
        recipeCode={recipeCode}
        portionSize={portionSize}
        equipmentOptions={equipmentOptions}
        stepNumber={item.stepNumber}
        nestIndex={index}
        stepTitle={item?.title || ''}
        portionSizePreview={portionSizePreview}
        measurementSystem={measurementSystem}
        viewScalableMethod={viewScalableMethod}
        {...useFormValues}
      />
      <HStack spacing="md" justifyContent="flex-end" mt="md">
        <Button
          variant="ghost"
          onClick={() => {
            // TODO: Bug - if there is an unsaved timer in one step, resetting another
            // step effects the most recently touched field in the step for which the
            // timer is being added.

            // Get the current form values
            const { recipeMethod } = getValues();
            // Filter out the current step from the current values
            const filteredRecipeMethodSteps = recipeMethod.filter(
              step => step.stepNumber !== item.stepNumber,
            );
            // Extract the default values for this current step
            // @ts-ignore
            const defaultValueCurrentStep: FormMethodStep[] = defaultValuesRef.current.recipeMethod
              ? defaultValuesRef.current.recipeMethod.filter(
                  step => step?.stepNumber === item.stepNumber,
                )
              : [];
            // Add the default value for this step back in to the
            // current values
            const defaultValues = [...filteredRecipeMethodSteps, ...defaultValueCurrentStep].sort(
              (a, b) => a.stepNumber - b.stepNumber,
            );
            // Reset the current step only, maintaining our original
            // default values from load.
            reset({ recipeMethod: defaultValues }, { keepDefaultValues: true });
          }}
          leftIcon={<MdIcon name="Replay" />}
        >
          Reset Method Step
        </Button>
        <Button
          onClick={() => removeStep(item.id, index)}
          variant="ghost"
          colorScheme="red"
          leftIcon={<MdIcon name="RemoveCircleOutline" />}
        >
          Remove Method Step
        </Button>
      </HStack>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody py={5}>
            {item.headerPhoto ? (
              <Image src={item.headerPhoto} borderRadius="sm" fallback={<FallbackImg />} />
            ) : (
              <VStack>
                <HiIcon
                  name="OutlineExclamationCircle"
                  color="yellow.400"
                  fontSize={24}
                  cursor="pointer"
                />
                <Text>Header Photo has not been uploaded yet for method {item.stepNumber}</Text>
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

function MethodStepFieldArray(
  props: UseFormReturn<FormState> & {
    isPrimaryPortion: boolean;
    recipeCode: string;
    portionSize: number;
    equipmentOptions: OptionType[];
    isLoading: boolean;
    onDeleteStep: (stepId: number) => void;
    onDeleteAllSteps: () => Promise<void>;
    portionSizePreview: number;
    measurementSystem: MeasurementSystem;
    isYoutubeRecipe: boolean;
    viewScalableMethod: boolean;
  },
) {
  const {
    control,
    formState,
    isLoading,
    isPrimaryPortion,
    onDeleteAllSteps,
    onDeleteStep,
    portionSize,
    portionSizePreview,
    measurementSystem,
    isYoutubeRecipe,
    viewScalableMethod,
  } = props;

  const dispatch = useDispatch();

  const { isOpen, onToggle } = useDisclosure();
  const [confirmDeleteAllSteps, setConfirmDeleteAllSteps] = useState(false);
  const [conversionInProgress, setConversionInProgress] = useState(false);

  const { register: registerMethodText, getValues: getMethodTextValue } = useForm<{
    recipeMethod: string;
  }>({ defaultValues: { recipeMethod: '' } });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'recipeMethod',
    keyName: 'uuid',
  });

  const onRemoveStep = async (stepId: number | undefined | null, index: number) => {
    if (!stepId) return;
    await onDeleteStep(stepId);
    remove(index);
  };

  const { isDirty } = formState;

  return (
    <Stack spacing="md">
      <Flex flexDirection="column" pt="md">
        <Flex alignItems="center" justifyContent="space-between">
          <Heading as="h3" size="md">
            Full Recipe Method - Quick Add
          </Heading>
          <Button onClick={onToggle}>Show/Hide</Button>
        </Flex>
        <Box mt="2">
          <Collapse in={isOpen} animateOpacity>
            <LabelTextArea
              placeholder="Recipe Method"
              name="recipeMethod"
              register={registerMethodText}
            />
            <HStack spacing="md" mt="2">
              <Button
                colorScheme="blue"
                onClick={async () => {
                  setConfirmDeleteAllSteps(true);
                }}
              >
                Generate Method
              </Button>
            </HStack>
          </Collapse>
          <ConfirmationModal
            isOpen={confirmDeleteAllSteps}
            onClose={() => setConfirmDeleteAllSteps(false)}
            onConfirm={async () => {
              setConversionInProgress(true);
              setConfirmDeleteAllSteps(false);

              const writtenMethod = getMethodTextValue().recipeMethod;
              const { payload, type: resType } = await dispatch(
                convertMethod({ method: writtenMethod, boldOnly: isYoutubeRecipe }),
              );

              if (!payload || typeof resType !== 'string' || !resType.includes('SUCCESS')) {
                return;
              }

              const convertedSteps = payload as { title: string; instructions: string }[];
              const methodSteps = convertedSteps.map(({ title, instructions }, index) => ({
                stepNumber: index + 1,
                tasks: [
                  {
                    taskNumber: 1,
                    method: '',
                    instructions,
                    id: undefined,
                    equipment: undefined,
                    audio: null,
                    timer: null,
                    numPeople: 2,
                  },
                ],
                title,
              }));

              await onDeleteAllSteps();
              remove();

              // Wait 200ms for the remove the complete before inserting the new values
              // (this is a hack to prevent clashes between old and new values)
              await sleep(200);
              append(methodSteps);
              setConversionInProgress(false);
            }}
            text="This will delete all existing steps - are you sure you wish to continue?"
            confirmLabel="Yes, Delete All Steps"
            cancelLabel="Close"
            confirmColor="red"
            cancelColor="gray"
          />
        </Box>
      </Flex>
      <Modal isOpen={conversionInProgress} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody py={5}>
            <VStack>
              <Spinner size="sm" />
              <Text>Please wait - this may take a couple of minutes...</Text>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Prompt when={isDirty} message="You have unsaved Changes. Are you sure you want to leave?" />
      {fields.length > 0 ? (
        fields
          .sort((a, b) => a.stepNumber - b.stepNumber)
          .map((item, index) => {
            return (
              <MethodStepField
                key={`method-step-${item.uuid}`}
                {...props}
                isPrimaryPortion={isPrimaryPortion}
                item={item}
                index={index}
                removeStep={onRemoveStep}
                portionSizePreview={portionSizePreview}
                measurementSystem={measurementSystem}
                viewScalableMethod={viewScalableMethod}
              />
            );
          })
      ) : (
        <Text size="md">This recipe method doesn&apos;t have any steps</Text>
      )}
      <HStack spacing="md">
        <Button
          type="submit"
          colorScheme="blue"
          disabled={!isDirty || isLoading}
          isLoading={isLoading}
        >
          Save Recipe Method
        </Button>
        <Button
          onClick={() => {
            append({
              title: `Step ${fields.length + 1}`,
              stepNumber: fields.length + 1,
              numPeople: portionSize,
            });
          }}
          variant="ghost"
          colorScheme="blue"
          leftIcon={<MdIcon name="Add" />}
          disabled={isLoading}
        >
          Add a Method Step
        </Button>
      </HStack>
    </Stack>
  );
}

// Only certain fields of a MethodStep can be updated over the API. Here we filter out the fields
// which can't be updated over the API before submitting the data
const removeStaticValuesFromData = (recipeMethod: FormMethodStep[]): UpdateableMethodStep[] => {
  return recipeMethod.map(step => {
    return {
      id: step.id,
      title: step.title,
      stepNumber: step.stepNumber,
      tasks: step.tasks.map(task => {
        return {
          id: task.id,
          method: task.method,
          instructions: task.instructions,
          timer: task.timer
            ? {
                id: task.timer.id,
                duration: task.timer.duration,
                instruction: task.timer.instruction,
                title: step.title,
              }
            : null,
          taskNumber: task.taskNumber,
        };
      }),
    };
  });
};

const RecipeMethodForm: React.FC<RecipeMethodFormProps> = ({
  title,
  recipeCode,
  equipmentOptions,
  portionSize,
  recipeMethod,
  isLoading = false,
  isSubmitting = false,
  isPrimaryPortion = false,
  isYoutubeRecipe = false,
  onSubmit,
  onDeleteStep,
  onDeleteAllSteps,
}) => {
  // Track our initial render cycle
  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  });
  const [portionSizePreview, setPortionSizePreview] = useState(2);
  const [measurementSystem, setMeasurementSystem] = useState<MeasurementSystem>('si');
  const [viewScalableMethod, setViewScalableMethod] = useState(true);

  // Used to prevent initial form resets in our useEffect's below
  const prevIsSubmitting = usePrevious(isSubmitting);
  const prevPortionSize = usePrevious(portionSize);
  const useFormValues = useForm({ defaultValues: { recipeMethod } });

  const { handleSubmit, reset } = useFormValues;
  const onSubmitData = handleSubmit(async values => {
    await onSubmit(removeStaticValuesFromData(values.recipeMethod));
  });

  useEffect(() => {
    // Prevent hook from resetting on initial load - this causes
    // issues with the internal state of our form
    if (firstUpdate.current) return;
    /**
     * Reset the form whenever the form has been submitted (i.e. the
     * RecipeIngredient prop has changed and the component is not in
     * an isSubmitting state). This is likely to happen on form submission
     * when we need to update the rows.
     *
     * The comparison vs. previous value is there to prevent an initial
     * reset of the form on mount.
     */
    if (!isSubmitting && isSubmitting !== prevIsSubmitting) {
      reset({ recipeMethod });
    }
  }, [isSubmitting, prevIsSubmitting]);

  useEffect(() => {
    // Prevent hook from resetting on initial load - this causes
    // issues with the internal state of our form

    if (firstUpdate.current) return;
    /**
     * useFieldArray hook is uncontrolled (as it allows dynamic inputs)
     * the "fields" prop does not update on rerender therefore we manually
     * reset the form on portionSize change
     *
     * The comparison vs. previous value is there to prevent an initial
     * reset of the form on mount.
     */
    if (portionSize !== prevPortionSize) {
      reset({ recipeMethod });
    }
  }, [portionSize]);

  return (
    <form onSubmit={onSubmitData}>
      <Heading as="h3" size="lg" mb="sm">
        {title}
      </Heading>

      <Flex
        flexDir="row"
        position="sticky"
        top={0}
        zIndex={1000}
        px="xs"
        py="xs"
        backgroundColor="gray.200"
      >
        <Flex
          flexDir="column"
          width={250}
          paddingRight={10}
          borderRightWidth={1}
          borderColor="grey.200"
        >
          <Text marginBottom={2} fontWeight="bold">
            Portion size :{' '}
          </Text>
          <Select
            options={[
              { label: 1, value: 1 },
              { label: 2, value: 2 },
              { label: 3, value: 3 },
              { label: 4, value: 4 },
              { label: 5, value: 5 },
              { label: 6, value: 6 },
              { label: 7, value: 7 },
              { label: 8, value: 8 },
              { label: 9, value: 9 },
              { label: 10, value: 10 },
            ]}
            onChange={e => {
              if (!e?.value) return;
              setPortionSizePreview(e.value);
            }}
            value={{
              label: portionSizePreview,
              value: portionSizePreview,
            }}
          />
        </Flex>
        <Flex flexDir="column" width={250} marginRight={5} paddingLeft={10}>
          <Text marginBottom={2} fontWeight="bold">
            Measurement System :{' '}
          </Text>
          <Select
            options={[
              { label: 'Metric', value: 'si' },
              { label: 'Imperial', value: 'imperial' },
            ]}
            onChange={e => {
              if (!e?.value) return;
              setMeasurementSystem(e.value);
            }}
            value={{
              label: measurementSystem === 'imperial' ? 'Imperial' : 'Metric',
              value: measurementSystem,
            }}
          />
        </Flex>
        <Flex flex={1} />
        <Flex flexDir="column" width={250} marginRight={5} paddingLeft={10} justifyContent="center">
          <Text marginBottom={2} fontWeight="bold">
            Currently Viewing:
          </Text>
          <Button onClick={() => setViewScalableMethod(prev => !prev)}>
            {viewScalableMethod ? 'Scaled Method' : 'Default Method'}
          </Button>
        </Flex>
      </Flex>

      <MethodStepFieldArray
        isPrimaryPortion={isPrimaryPortion}
        recipeCode={recipeCode}
        portionSize={portionSize}
        equipmentOptions={equipmentOptions}
        isLoading={isLoading || isSubmitting}
        onDeleteStep={onDeleteStep}
        onDeleteAllSteps={onDeleteAllSteps}
        portionSizePreview={portionSizePreview}
        measurementSystem={measurementSystem}
        isYoutubeRecipe={isYoutubeRecipe}
        viewScalableMethod={viewScalableMethod}
        {...useFormValues}
      />
    </form>
  );
};

export default RecipeMethodForm;
