import {
  Button,
  Flex,
  HStack,
  Skeleton,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'hooks';

import filter from 'lodash/filter';
import find from 'lodash/find';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';

import buildAssetString from 'utils/buildAssetString';

import { retrievePack } from 'redux/actions/pack';
import {
  createMethodStep,
  createRecipeIngredients,
  deleteRecipeIngredients,
  duplicatePortion,
  editMethodStep,
  listMethodSteps,
  listRecipeIngredients,
  removeMethodStep,
  retrieveRecipe,
  updateRecipe,
  updateRecipeIngredients,
} from 'redux/actions/recipe';

import ConfirmationModal from 'components/ConfirmationModal';
import PDFModal, { DocumentProps } from 'components/PDFModal';
import ScreenWrapper from 'components/ScreenWrapper';

import { useTitle } from 'hooks';

import { CALCULATED_ATTRIBUTES } from 'constants/data';
import { toast } from 'navigation/AppRouter';
import { createRecipeChefTwist, updateTwist } from '../../redux/actions/twists';

import { RecipeDetailForm, RecipeIngredientForm, RecipeMethodForm } from './Forms';

import { IngredientFormRow } from './Forms/RecipeIngredientForm';

import {
  UpdateableMethodStep,
  CreateTwistPayload,
  FormMethodStep,
  GlobalState,
  IngredientState,
  MethodStep,
  Quantity,
  Recipe,
  RecipeIngredient,
  NutritionalInfo,
} from 'types';
import RecipeTwistForm, { RecipeTwistFormRow } from './Forms/RecipeTwistForm';

export interface RecipeIngredientFiltered
  extends Pick<RecipeIngredient, 'id' | 'index' | 'ingredient' | 'group'> {
  metric: Quantity;
  imperial?: Quantity | null;
  disableScaling?: boolean;
}

/**
 * This function is used to convert the instructions from the scalable format to the
 * non-scalable format (portion size = 2). This is used for the PDF export and CSV files.
 * @param instructions
 * @returns
 */
export const convertInstructions = (instructions: string) => {
  const replaceFunc = (
    match: string,
    quantity: string,
    _: any,
    __: any,
    unit: string,
    ___: any,
    name: string,
  ) => {
    const quantityFormatted = `${quantity || ''}${
      quantity && ['tsp', 'tbsp'].includes(unit) ? ' ' : ''
    }${unit || ''}`;

    let nameFormatted = name;
    if (nameFormatted) {
      nameFormatted = nameFormatted
        .replace('(s)', parseFloat(quantity) > 1 ? 's' : '')
        .replace('(es)', parseFloat(quantity) > 1 ? 'es' : '');
    }

    return nameFormatted || quantityFormatted;
  };

  const regexValues = /\{\{value:(\d+(\.\d+)?)(\|unit:([^}]+))?(\|name:([^}]+))?\}\}/g;
  let text = instructions.replace(regexValues, replaceFunc);

  const regexValueNotScaled =
    /\{\{value_not_scaled:(\d+(\.\d+)?)(\|unit:([^}]+))?(\|name:([^}]+))?\}\}/g;
  text = text.replace(regexValueNotScaled, replaceFunc);

  // Replace ** with emtpy string
  text = text.replace(/\*\*/g, '');

  return text;
};

/** START: FORM DATA BUILDERS */
export const buildDefaultRecipeIngredients = (
  recipeIngredients: RecipeIngredient[],
  ingredients: IngredientState,
): RecipeIngredientFiltered[] => {
  /**
   * Build the data for the Primary and Secondary
   * RecipeIngredients. This is determined by the num people defined
   * on the RecipePack
   */
  return sortBy(recipeIngredients, ['index', 'id'])
    .filter(recipeIng => {
      const ingredient = recipeIng.ingredient ? ingredients[recipeIng.ingredient] : null;
      // Only display recipe ingredients which have a type, and only
      // if that type isn't 'Equipment' -> i.e. only show food ingredients
      return ingredient && ingredient.type ? ingredient.type.name !== 'Equipment' : false;
    })
    .map(recipeIng => {
      const quantities = recipeIng.quantities.filter(
        // @ts-ignore - TODO : fix this once the backend has been updated
        quantity => !quantity.numPeople || quantity.numPeople === 2,
      );

      // For the given num people, we should have the following scenarios:
      // - 1 quantity: in this scenario the quantity is shared for both metric &
      //   imperial - e.g. tsp
      // - 2+ quantities: in this scenario there is a distinct imperial and metric
      //   quantity - so use both
      const metric =
        quantities.length > 1
          ? (quantities.find(q => q.unit.system === 'si') as Quantity)
          : quantities[0];

      const imperial =
        quantities.length > 1
          ? (quantities.find(q => q.unit.system === 'imperial') as Quantity)
          : quantities[0];

      return {
        // Only keep the recipe ingredient id, index and ingredient keys. `id` & `index`
        // are used directly as values within our recipe ingredient form, whereas `ingredient`
        // is taken and transformed into an `OptionType` before it's used within the form
        ...pick(recipeIng, ['id', 'index', 'ingredient', 'group', 'disableScaling']),
        metric,
        imperial,
      };
    });
};

export const buildDefaultRecipeMethod = (methodSteps: MethodStep[]): FormMethodStep[] => {
  return (
    methodSteps
      .sort((a, b) => a.stepNumber - b.stepNumber)
      // TODO : Fix this once the backend has been updated
      .filter(step => !step.numPeople || step.numPeople === 2)
  );
};
/** END: FORM DATA BUILDERS */

/** START: RECIPE INGREDIENT DATA TRANSFORM */
const groupRecipeIngredients = (
  values: IngredientFormRow[],
  recipeIngredients: RecipeIngredient[],
) => {
  // Recipe ingredients that already exist and should be updated. We know
  // a recipe ingredient already exists if it has a `recipeIngredientId` set
  const toUpdate = values.filter(value => Boolean(value.recipeIngredientId));

  // Recipe ingredients that do not exist and therefore need to be created.
  // We know a recipe ingredient doesn't already exist if it does not have a
  // `recipeIngredientId` set
  const toCreate = values.filter(value => !value.recipeIngredientId);

  // Recipe ingredients that no longer exist and therefore need to be deleted.
  // We need to ensure that we're only deleting the recipe ingredient quantity
  // for the selected portion size, and not delete the entire recipe ingredient
  // wrapper.
  const toDelete = recipeIngredients.filter(
    recipeIng => !find(values, value => value.recipeIngredientId === recipeIng.id),
  );

  return {
    toUpdate,
    toCreate,
    toDelete,
  };
};

const formatRecipeIngredients = (
  values: IngredientFormRow[],
  recipeId: number,
  recipeIngredients: RecipeIngredient[],
) => {
  const { toUpdate, toCreate, toDelete } = groupRecipeIngredients(values, recipeIngredients);

  const updateData = toUpdate.map(value => {
    return {
      id: value.recipeIngredientId,
      recipe: recipeId,
      ingredient: value.ingredient?.value,
      index: value.index,
      group: value.group,
      disableScaling: value.disableScaling,
      quantities: [
        {
          ...value.metric,
          unit: {
            id: value.unit?.value,
          },
        },
      ],
    };
  });

  const createData = toCreate.map(value => ({
    recipe: recipeId,
    ingredient: value.ingredient?.value,
    index: value.index,
    group: value.group,
    disableScaling: value.disableScaling,
    quantities: [
      {
        // @ts-ignore
        id: null,
        ...value.metric,
        unit: {
          id: value.unit?.value,
        },
      },
    ],
  }));

  const deleteData = toDelete.map(recipeIng => ({
    id: recipeIng.id as number,
    quantities: recipeIng.quantities,
  }));

  return {
    updateData,
    createData,
    deleteData,
  };
};
/** END: RECIPE INGREDIENT DATA TRANSFORM */

/** START: RECIPE METHOD DATA TRANSFORM */
const groupMethodSteps = (values: UpdateableMethodStep[]) => {
  // Method steps that already exist should be updated. We know
  // a method step already exists if it has an `id` set which is a number
  const toUpdate = values.filter(value => Boolean(value.id) && typeof value.id === 'number');

  // Method steps that do not exist and therefore need to be created.
  // We know a method step doesn't already exist if it has an empty string `id` set
  const toCreate = values.filter(value => !value.id || typeof value.id === 'string');

  return {
    toUpdate,
    toCreate,
  };
};

const removeNullValuesFromSteps = (steps: UpdateableMethodStep[]) => {
  return steps.map(step => {
    return {
      ...step,
      tasks: step.tasks.map(t => {
        const { timer, ...task } = t;
        return {
          ...task,
          ...(timer !== null ? { timer } : {}),
        };
      }),
    };
  });
};

const formatMethodSteps = (steps: UpdateableMethodStep[]) => {
  const { toUpdate, toCreate } = groupMethodSteps(removeNullValuesFromSteps(steps));

  return {
    updateData: toUpdate,
    createData: toCreate,
  };
};
/** END: RECIPE METHOD DATA TRANSFORM */

const PortionTabStack: React.FC<{
  ingredients: IngredientState;
  recipeIngredients: RecipeIngredient[];
  units: GlobalState['common']['units'];
  methodSteps: MethodStep[];
  recipe: Recipe;
  isPrimaryPortion: boolean;
  portionSize?: number;
  isSubmitting: { ingredients: boolean; method: boolean };
  isLoading: boolean;
  nutritionalInfo: NutritionalInfo;
  onSubmitIngredients: (values: IngredientFormRow[]) => Promise<boolean>;
  onSubmitMethod: (values: UpdateableMethodStep[]) => Promise<boolean>;
  onDeleteStep: (stepId: number) => void;
  onDeleteAllSteps: () => Promise<void>;
}> = ({
  ingredients,
  recipeIngredients,
  units,
  portionSize = 2,
  methodSteps,
  isSubmitting,
  isLoading,
  recipe,
  isPrimaryPortion,
  nutritionalInfo,
  onSubmitIngredients,
  onSubmitMethod,
  onDeleteStep,
  onDeleteAllSteps,
}) => {
  // Translate our raw list of units & ingredients into a filtered list of
  // valid options for a `Select` widget -> the list of available units should
  // be dynamic based on the selected ingredient.
  const unitOptions = Object.values(units)
    .filter(
      unit => unit.system !== 'imperial' || unit.nameAbbrev === 'tsp' || unit.nameAbbrev === 'tbsp',
    )
    .map(unit => ({
      label: unit.nameAbbrev,
      value: unit.id,
    }));

  const ingredientOptions = Object.values(ingredients).map(ingredient => ({
    label: ingredient.name,
    value: ingredient.id,
  }));

  const equipmentOptions = Object.values(ingredients)
    .filter(ingredient => ingredient.type?.name === 'Equipment')
    .map(ingredient => ({
      label: ingredient.name,
      value: ingredient.id,
    }));

  return portionSize !== undefined ? (
    <Stack spacing="sm" divider={<StackDivider borderColor="gray.200" />}>
      <RecipeIngredientForm
        unitOptions={unitOptions}
        ingredientOptions={ingredientOptions}
        recipeIngredients={buildDefaultRecipeIngredients(recipeIngredients, ingredients)}
        title={`Ingredients - ${portionSize} People`}
        portionSize={portionSize}
        onSubmit={onSubmitIngredients}
        isLoading={isLoading}
        isSubmitting={isSubmitting.ingredients}
      />
      <Flex>
        {[
          { label: 'kcal', val: recipe?.nutritionalInfo.energyKcal, hideUnit: true },
          { label: 'carbs', val: recipe?.nutritionalInfo.carbohydrate },
          { label: 'fat', val: recipe?.nutritionalInfo.fat },
          { label: 'protein', val: recipe?.nutritionalInfo.protein },
          { label: 'salt', val: recipe?.nutritionalInfo.salt },
          { label: 'sugar', val: recipe?.nutritionalInfo.sugars },
          { label: 'fibre', val: recipe?.nutritionalInfo.fibre },
          { label: 'sat fat', val: recipe?.nutritionalInfo.saturatedFat },
        ].map(({ label, val, hideUnit }, idx) => (
          <Flex
            key={label}
            borderColor="gray.300"
            borderWidth={2}
            width={75}
            height={75}
            borderRadius="lg"
            overflow="hidden"
            backgroundColor="gray.200"
            flexDir="column"
            mr="sm"
          >
            <Flex
              backgroundColor="gray.300"
              width="100%"
              justifyContent="center"
              alignItems="center"
              height="50%"
            >
              <Text fontWeight="bold">{label}</Text>
            </Flex>
            <Flex width="100%" justifyContent="center" alignItems="center" height="50%">
              <Text variant="body">
                {val && val < 1 ? val * 1000 : ''}
                {val && val >= 1 && val <= 10 ? val : ''}
                {val && val > 10 ? parseInt(val.toString()) : ''}
                {hideUnit ? '' : val && val < 1 ? 'mg' : 'g'}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
      <RecipeMethodForm
        isPrimaryPortion={isPrimaryPortion}
        recipeMethod={buildDefaultRecipeMethod(methodSteps)}
        equipmentOptions={equipmentOptions}
        title={`Recipe Method - ${portionSize} People`}
        onSubmit={onSubmitMethod}
        isLoading={isLoading}
        isSubmitting={isSubmitting.method}
        portionSize={portionSize}
        recipeCode={recipe.code}
        isYoutubeRecipe={recipe.recipeType === 'youtube'}
        onDeleteStep={onDeleteStep}
        onDeleteAllSteps={onDeleteAllSteps}
      />
    </Stack>
  ) : null;
};

const RecipeScreen: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  if (!params.id) return null;

  const { id: recipeId } = params;

  // Extract the recipe from the state
  const recipe = useSelector((state: GlobalState) => state.recipe.list[recipeId]);
  // Extract the pack for this recipe
  const pack = useSelector((state: GlobalState) =>
    recipe?.pack ? state.pack.detail[recipe.pack] : undefined,
  );
  // Select the recipe ingredients for this recipe
  const allRecipeIngredients = useSelector((state: GlobalState) => state.recipe.ingredients);
  const recipeIngredients = recipe ? filter(allRecipeIngredients, o => o.recipe === recipe.id) : [];

  // Select the method steps for this recipe
  const allMethodSteps = useSelector((state: GlobalState) => state.recipe.methodSteps);
  const methodSteps = recipe ? filter(allMethodSteps, o => o.method === recipe.method) : [];

  // Select general data
  const ingredients = useSelector((state: GlobalState) => state.common.ingredients);
  const cuisineTags = useSelector((state: GlobalState) => state.common.cuisineTags);
  const attributeTags = useSelector((state: GlobalState) => state.common.attributeTags);
  const units = useSelector((state: GlobalState) => state.common.units);

  const recipeMethod = recipe?.method;

  // Setup page title
  let pageTitle = '';

  if (recipe) {
    const { code, title } = recipe;
    pageTitle = title;

    if (code) {
      pageTitle = title ? `${code} : ${title}` : code;
    }
  }

  useTitle(pageTitle);

  // Loading for the entire page
  const [isLoading, setIsLoading] = useState(true);

  // PDF Modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Loading for duplication button
  const [isDuplicating, setIsDuplicating] = useState(false);

  // Opens/Closes the Confirmation Modal for duplicating portions
  const [confirmDuplicateModalIsOpen, setConfirmDuplicateModalIsOpen] = useState(false);

  const [confirmDeleteStepID, setConfirmDeleteStepModalID] = useState<number | null>(null);

  const [isSubmitting, setIsSubmitting] = useState({
    detail: false,
    ingredients: false,
    method: false,
    twist: false,
  });

  // Initial data load on mount
  useEffect(() => {
    async function getRecipe() {
      const recipeResponse = await dispatch(retrieveRecipe(parseInt(recipeId)));

      if (recipeResponse.error) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Could not fetch the recipe',
          isClosable: true,
        });
        return;
      }

      return recipeResponse;
    }

    async function getRecipeIngredients() {
      const recipeIngredientResponse = await dispatch(listRecipeIngredients(parseInt(recipeId)));

      if (recipeIngredientResponse.error) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Could not fetch Recipe Ingredients',
          isClosable: true,
        });
      }
    }

    async function getPack(packId: number) {
      const packResponse = await dispatch(retrievePack(packId));

      if (packResponse.error) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Could not fetch Packs',
          isClosable: true,
        });
      }
    }

    async function getMethodSteps(methodId: number) {
      const recipeMethodResponse = await dispatch(listMethodSteps(methodId));

      if (recipeMethodResponse.error) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Could not fetch Method Steps',
        });
      }
    }

    const loadData = async () => {
      // Start by loading the recipe data
      const recipeResponse = await getRecipe();

      if (!recipeResponse) {
        setIsLoading(false);
        return;
      }

      // Build an array of subsequent API calls to make, some of which depend
      // on the response from the initial get recipe call
      const actions = [getRecipeIngredients()];

      if (recipeResponse?.payload && 'pack' in recipeResponse.payload) {
        const { pack: packId, method: methodId } = recipeResponse.payload;

        if (packId) actions.push(getPack(packId));
        if (methodId) actions.push(getMethodSteps(methodId));
      }

      await Promise.all(actions);

      setIsLoading(false);
    };

    loadData();
    // If recipeId changes, e.g. from using the 'create recipe' modal whilst still
    // on the recipe page - trigger a reload
  }, [recipeId]);

  /** HANDLERS */
  const handleRecipeDetailFormSubmit = async (values: Partial<Recipe>) => {
    setIsSubmitting(prev => ({ ...prev, detail: true }));

    // Filter empty tags
    const response = await dispatch(
      updateRecipe(parseInt(recipeId), {
        ...values,
        cuisineTags: values.cuisineTags?.filter(tag => tag),
        seasonalTags: values.seasonalTags?.filter(tag => tag),
        attributeTags: values.attributeTags?.filter(
          tag => tag && !CALCULATED_ATTRIBUTES.includes(tag),
        ),
      }),
    );

    if (response.error) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Could not update the recipe',
        isClosable: true,
      });
    } else {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Recipe updated',
        isClosable: true,
      });
    }

    setIsSubmitting(prev => ({ ...prev, detail: false }));

    return Boolean(response.error);
  };

  const handleSubmitRecipeIngredients = async (values: IngredientFormRow[]) => {
    setIsSubmitting(prev => ({ ...prev, ingredients: true }));

    // We take the recipe ingredient form data and split it into 3 API calls:
    // Update, Create, and Delete
    const { updateData, createData, deleteData } = formatRecipeIngredients(
      values,
      parseInt(recipeId),
      recipeIngredients,
    );

    const updateResponse = updateData.length
      ? await dispatch(updateRecipeIngredients(updateData))
      : null;

    const createResponse = createData.length
      ? await dispatch(createRecipeIngredients(parseInt(recipeId), createData))
      : null;

    const deleteResponse = deleteData.length
      ? await dispatch(deleteRecipeIngredients(deleteData))
      : null;

    setIsSubmitting(prev => ({ ...prev, ingredients: false }));

    if (createResponse?.error || updateResponse?.error || deleteResponse?.error) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Could not update Recipe Ingredients',
        isClosable: true,
      });
      return false;
    } else {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Recipe Ingredients updated',
        isClosable: true,
      });
    }
    return true;
  };

  const handleSubmitChefTwist = async (values: {
    recipeTwists: RecipeTwistFormRow[];
    deletedTwists: RecipeTwistFormRow[];
  }) => {
    setIsSubmitting({
      ...isSubmitting,
      twist: true,
    });
    const createTwistPayload: CreateTwistPayload[] = values.recipeTwists
      .filter(item => !item.recipeTwistId)
      .map(item => {
        return {
          contentType: 'recipe',
          comment: item.comment,
          title: item.title,
          objectId: parseInt(recipeId),
        };
      });
    const updateTwistPayloadPromises = values.recipeTwists
      .filter(item => item.recipeTwistId)
      .map(twist => {
        if (!twist.recipeTwistId) {
          return null;
        }
        return dispatch(
          updateTwist(twist.recipeTwistId, {
            comment: twist.comment,
            title: twist.title,
            approved: 'True',
          }),
        );
      });
    const deleteTwistPayloadPromises = values.deletedTwists
      .filter(item => item.recipeTwistId)
      .map(twist => {
        if (!twist.recipeTwistId) {
          return null;
        }
        return dispatch(
          updateTwist(twist.recipeTwistId, {
            comment: twist.comment,
            title: twist.title,
            approved: 'False',
          }),
        );
      });
    await Promise.all([
      dispatch(createRecipeChefTwist(createTwistPayload)),
      ...updateTwistPayloadPromises,
      ...deleteTwistPayloadPromises,
    ]);
    await dispatch(retrieveRecipe(parseInt(recipeId)));
    setIsSubmitting({
      ...isSubmitting,
      twist: false,
    });
  };

  const handleSubmitRecipeMethod = async (values: UpdateableMethodStep[]) => {
    if (!recipeMethod) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Unable to save data. Please refresh to try again.',
        isClosable: true,
      });
      return false;
    }

    setIsSubmitting(prev => ({ ...prev, method: true }));

    // We take the recipe ingredient form data and split it into 2 API calls: Update & Create
    const { updateData, createData } = formatMethodSteps(values);

    const updateResponse = await Promise.all(
      updateData.map(async data => await dispatch(editMethodStep(data.id as number, data))),
    );

    const createResponse = await Promise.all(
      createData.map(async data => await dispatch(createMethodStep(recipeMethod, data))),
    );

    setIsSubmitting(prev => ({ ...prev, method: false }));

    if (
      createResponse.some(response => response.error) ||
      updateResponse.some(response => response.error)
    ) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Could not update recipe method',
        isClosable: true,
      });
      return false;
    } else {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Recipe method updated',
        isClosable: true,
      });
    }
    return true;
  };

  const handleDuplicatePortion = async () => {
    setIsDuplicating(true);
    const response = await dispatch(duplicatePortion(recipe.id));

    if (response.error) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Could not duplicate Primary Portion',
        isClosable: true,
      });
    } else {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Primary Portion has been copied across to Secondary Portion',
        isClosable: true,
      });
    }
    setIsDuplicating(false);
    setConfirmDuplicateModalIsOpen(false);
  };

  const handleStepDelete = async (stepId: number | null) => {
    if (stepId === null) return;

    setIsSubmitting(prev => ({ ...prev, detail: true }));
    const res = await dispatch(removeMethodStep(stepId));

    setIsSubmitting(prev => ({ ...prev, detail: false }));
    setConfirmDeleteStepModalID(null);

    const toastMessage = res.error
      ? ({
          status: 'error',
          title: 'Error',
          description: 'Could not update recipe method',
          isClosable: true,
        } as const)
      : ({
          status: 'success',
          title: 'Success',
          description: 'Recipe step deleted',
          isClosable: true,
        } as const);

    toast(toastMessage);
  };

  const handleDeleteAllSteps = async () => {
    setIsSubmitting(prev => ({ ...prev, detail: true }));

    // Sequentially delete each step to avoid transaction blocks on the backend
    let hasError = false;
    for (const step of methodSteps) {
      const response = await dispatch(removeMethodStep(step.id));
      if (response.error) hasError = true;
    }

    const toastMessage = hasError
      ? ({
          status: 'error',
          title: 'Error',
          description: 'Could not update recipe method',
          isClosable: true,
        } as const)
      : ({
          status: 'success',
          title: 'Success',
          description: 'Recipe steps deleted',
          isClosable: true,
        } as const);

    toast(toastMessage);

    setIsSubmitting(prev => ({ ...prev, detail: false }));
  };

  //
  const buildPdfData = (): DocumentProps => {
    /**
     * Builds the data for the PDF Modal for the primary portion
     * only
     */

    const primaryRecipeIngredients = buildDefaultRecipeIngredients(recipeIngredients, ingredients);

    const primaryRecipeMethod = buildDefaultRecipeMethod(methodSteps);

    const methodStepSection = primaryRecipeMethod.map(methodStep => ({
      title: methodStep.title || '',
      number: methodStep.stepNumber,
      items: methodStep.tasks.map(task => {
        let item = convertInstructions(task.instructions);

        if (task.timer?.instruction) {
          item = `${item}\r\n\nTimer Instruction: ${task.timer.instruction}`;
        }

        return item;
      }),
    }));

    return {
      packCode: pack?.code,
      packStory: pack?.story,
      packTitle: pack?.name,
      code: recipe.code,
      title: recipe.title,
      recipeStory: recipe.story,
      sections: [
        {
          title: 'Ingredients',
          items: primaryRecipeIngredients
            .filter(({ metric }) => !!metric)
            .map(
              ({ ingredient, metric }) =>
                `${(metric as Quantity).quantity} ${(metric as Quantity).unit.pluralAbbrev} ${
                  ingredient ? ingredients[ingredient].name : ''
                } ${(metric as Quantity).prepInstructions}`,
            ),
        },
        ...methodStepSection,
      ],
    };
  };

  //
  const buildCsvData = () => {
    /**
     * Builds the CSV data
     */
    const csvData: {
      Recipe: string;
      Text: string;
      'Asset Code': string;
    }[] = [];

    methodSteps.forEach(methodStep => {
      const { tasks } = methodStep;
      (tasks || []).forEach(task => {
        // Convert timer duration from seconds to minutes
        const duration = task.timer?.duration;
        const timerDuration = duration && Math.floor(duration / 60);

        // Add task row
        csvData.push({
          Recipe: recipe.code,
          Text: convertInstructions(task.instructions),
          'Asset Code': buildAssetString({
            recipeCode: recipe.code,
            stepNumber: methodStep.stepNumber,
            portion: 2,
            taskNumber: task.taskNumber,
            suffix: '.mp3',
            isTimer: !!task.timer,
            timerDuration,
          }),
        });

        // Add timer row
        if (task.timer?.instruction) {
          csvData.push({
            Recipe: recipe.code,
            Text: task.timer.instruction,
            'Asset Code': buildAssetString({
              recipeCode: recipe.code,
              stepNumber: methodStep.stepNumber,
              portion: 2,
              taskNumber: task.taskNumber,
              suffix: '.mp3',
              isTimer: true,
              isEnd: true,
            }),
          });
        }
      });
    });

    return csvData;
  };

  if (isLoading || !recipe) {
    return (
      <ScreenWrapper>
        <Stack padding="md">
          <Skeleton height="40px" width="30%" />
          <Skeleton height="20px" width="80%" />
          <Skeleton height="20px" width="80%" />
          <Skeleton height="20px" width="80%" />
        </Stack>
      </ScreenWrapper>
    );
  }

  const equipmentOptions = Object.values(ingredients)
    .filter(ingredient => ingredient.type?.name === 'Equipment')
    .map(ingredient => ({
      label: ingredient.name,
      value: ingredient.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <ScreenWrapper>
      <Stack spacing="md" divider={<StackDivider borderColor="gray.200" />}>
        <RecipeDetailForm
          recipe={recipe}
          pack={pack || null}
          onSubmit={handleRecipeDetailFormSubmit}
          isLoading={isLoading}
          isSubmitting={isSubmitting.detail}
          equipmentOptions={equipmentOptions}
          cuisineTags={Object.values(cuisineTags).map(tag => tag.name)}
          attributeTags={Object.values(attributeTags).map(tag => tag.name)}
        />
        <Flex flexDir="column">
          <RecipeTwistForm
            recipeTwists={recipe.twists}
            isSubmitting={isSubmitting.twist}
            onSubmit={handleSubmitChefTwist}
          />
        </Flex>
        <Flex flexDir="column">
          <PortionTabStack
            ingredients={ingredients}
            recipeIngredients={recipeIngredients}
            units={units}
            methodSteps={methodSteps}
            recipe={recipe}
            isPrimaryPortion={true}
            isLoading={isLoading}
            isSubmitting={{ ingredients: isSubmitting.ingredients, method: isSubmitting.method }}
            nutritionalInfo={recipe.nutritionalInfo}
            onSubmitIngredients={handleSubmitRecipeIngredients}
            onSubmitMethod={handleSubmitRecipeMethod}
            onDeleteStep={(id: number) => setConfirmDeleteStepModalID(id)}
            onDeleteAllSteps={async () => await handleDeleteAllSteps()}
          />
        </Flex>

        <Flex flexDirection="column">
          <Text fontWeight="bold">Recipe Actions:</Text>
          <HStack mt="sm" spacing="sm">
            <Button colorScheme="blue" onClick={onOpen}>
              Export PDF
            </Button>
            {/* @ts-ignore */}
            <CSVLink data={buildCsvData()} filename={`${recipe.code}_timer_instructions.csv`}>
              <Button>Export Script CSV</Button>
            </CSVLink>
          </HStack>
        </Flex>
      </Stack>
      <PDFModal isOpen={isOpen} onClose={onClose} pdfData={isOpen ? buildPdfData() : null} />
      <ConfirmationModal
        isOpen={confirmDuplicateModalIsOpen}
        onClose={() => setConfirmDuplicateModalIsOpen(false)}
        onConfirm={handleDuplicatePortion}
        text="Duplicating will overwrite any current Ingredients and Steps in the Secondary Portion."
        isLoading={isDuplicating}
        confirmLabel="Duplicate Portion"
        cancelLabel="Close"
        confirmColor="blue"
        cancelColor="gray"
      />
      <ConfirmationModal
        isOpen={typeof confirmDeleteStepID === 'number'}
        onClose={() => setConfirmDeleteStepModalID(null)}
        onConfirm={() => handleStepDelete(confirmDeleteStepID)}
        text="Are you sure you wish to delete this step?"
        confirmLabel="Delete Step"
        cancelLabel="Close"
        confirmColor="blue"
        cancelColor="gray"
      />
    </ScreenWrapper>
  );
};

export default RecipeScreen;
