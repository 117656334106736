import React, { useState, useEffect, useRef } from 'react';
import {
  StackDivider,
  Skeleton,
  Stack,
  UseToastOptions,
  Heading,
  Flex,
  HStack,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  ModalHeader,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { useForm, useDispatch } from 'hooks';

import { GlobalState, Pack, Recipe, RecipeIngredient, MethodStep, Quantity } from 'types';

import {
  retrievePack,
  updatePack,
  listPackRecipes,
  removePackRecipe,
  duplicatePack,
} from 'redux/actions/pack';

import {
  createRecipe,
  createMethodStep,
  listRecipeIngredients,
  listMethodSteps,
} from 'redux/actions/recipe';

import { toast } from 'navigation/AppRouter';
import routes from 'navigation/routes';

import {
  buildDefaultRecipeIngredients,
  buildDefaultRecipeMethod,
  convertInstructions,
} from 'screens/Recipes/RecipeScreen';

import Prompt from 'components/Prompt';
import ScreenWrapper from 'components/ScreenWrapper';
import ShoppingList, { ShoppingListData } from 'components/ShoppingList';
import EquipmentList from 'components/EquipmentList';
import { DocumentProps } from 'components/PDFModal';
import PDFModalMultiple from 'components/PDFModalMultiple';
import LabelInput from 'components/LabelInput';
import { useTitle } from 'hooks';

import { PackRecipesForm, PackDetailsForm, PackAdditionalDetailsForm } from './Forms';

import { ingredientTags } from 'constants/data';
import buildAssetString from 'utils/buildAssetString';
import { useShoppingList } from 'hooks/useShoppingList';

/**
 * Turn equipment shopping list data into an array of shopping list items
 * which can be used to display a list of equipment on screen
 */
const buildEquipmentData = (
  data: ShoppingListData['equipment'],
  portionType: 'primary' | 'secondary',
  primary: number,
  secondary: number,
) => {
  const portionSize = portionType === 'primary' ? primary : secondary;
  const values = data[portionSize] || data.none;
  return values
    ? Object.values(values)
        .flatMap(unit => {
          // Equipment is measurement system agnostic (i.e. not imperial or metric)
          // and will therefore be 'none'.
          return unit.none;
        })
        // Filter out undefined values
        .filter(a => Boolean(a))
    : [];
};

/**
 * Turn weekly & staple shopping list data, for a specific portion size, into an
 * array of objects which contain both metric and imperial definitions for a single
 * shopping list item
 */
const buildIngredientData = (
  data: ShoppingListData['weekly'][number] | ShoppingListData['staple'][number],
) => {
  return Object.values(data).flatMap(unit => {
    return unit;
  });
};

const MealPackScreen: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  if (params.id === undefined) return null;
  const { id: packId } = params;

  // Load data
  const pack = useSelector((state: GlobalState) => state.pack.detail[packId]);
  const recipes = useSelector((state: GlobalState) =>
    Object.values(state.recipe.list).filter(recipe => recipe.pack === parseInt(packId)),
  );
  const packThemes = useSelector((state: GlobalState) => state.common.packThemes);
  const ingredients = useSelector((state: GlobalState) => state.common.ingredients);

  // Loading for the entire page
  const [isLoading, setIsLoading] = useState(true);
  const [isPdfOpen, setIsPdfOpen] = useState(false);

  const [pdfData, setPdfData] = useState<DocumentProps[] | null>(null);
  const [csvData, setCsvData] = useState<any>([]);

  const [isCsvDataLoading, setIsCsvDataLoading] = useState(false);
  const [isPdfDataLoading, setIsPdfDataLoading] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState({
    primary: false,
    secondary: false,
    recipes: false,
    newPack: false,
  });

  const shoppingListData = useShoppingList(parseInt(packId));
  const csvRef = useRef<any>(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Partial<{ newCode: '' }>>();

  let pageTitle = '';

  if (pack) {
    const { code, name } = pack;
    pageTitle = name;

    if (code) {
      pageTitle = name ? `${code}: ${name}` : code;
    }
  }

  useTitle(pageTitle);

  // Initial data load on mount
  useEffect(() => {
    async function getPack() {
      const packResponse = await dispatch(retrievePack(parseInt(packId)));
      if (packResponse.error) {
        toast.closeAll();
        toast({
          title: 'Meal Pack Error',
          description: `Pack with ID ${packId} could not be loaded`,
          status: 'error',
          isClosable: true,
        });
      }
    }

    async function getPackRecipes() {
      const listAction = listPackRecipes(parseInt(packId));
      const packRecipesResponse = await dispatch(listAction);

      if (packRecipesResponse.error) {
        toast.closeAll();
        toast({
          title: 'Pack Recipe Error',
          description: `Recipes for Pack with ID ${packId} could not be loaded`,
          status: 'error',
          isClosable: true,
        });
      }
    }

    getPack();
    getPackRecipes();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    /**
     * useEffect for manually clicking on the CSV React Component
     * only when the CSV Data has been populated
     */
    if (csvRef?.current?.link && csvData.length) {
      // The CSV component will be referencing the previous empty csvData state
      // when we call its ref click function but using setTimeout gets around this
      // by sending the function to the back of JavaScript's invocation queue ensuring
      // the CSV component is using the latest csvData
      // See: https://javascriptweblog.wordpress.com/2010/06/28/understanding-javascript-timers/
      setTimeout(() => {
        csvRef.current.link.click();
        setCsvData([]);
      });
    }
  }, [csvData]);

  /** HANDLERS */
  const handlePackDetailsSubmit = async (values: Partial<Pack>) => {
    // Update the Pack details here
    setIsSubmitting(prev => ({ ...prev, primary: true }));

    const response = await dispatch(updatePack(parseInt(packId), { ...values }));

    if (response.error) {
      toast({
        title: 'Pack could not be updated',
        isClosable: true,
        status: 'error',
        duration: 2000,
      });
    } else {
      toast({
        title: 'Pack Updated',
        isClosable: true,
        status: 'success',
        duration: 2000,
      });
    }
    setIsSubmitting(prev => ({ ...prev, primary: false }));
  };

  const handlePackSecondaryFormSubmit = async (values: { [key: string]: string }) => {
    setIsSubmitting(prev => ({ ...prev, secondary: true }));
    const filterTags = (filterKey: string) =>
      Object.keys(values)
        .filter(key => key.includes(filterKey))
        .map(key => values[key])
        .filter(tag => tag);

    await dispatch(updatePack(parseInt(packId), { themes: filterTags('theme-tag') }));

    setIsSubmitting(prev => ({ ...prev, secondary: false }));
    toast({
      title: 'Pack Tags Updated',
      isClosable: true,
      status: 'success',
      duration: 2000,
    });
  };

  const handleAddRecipePack = async (data: Partial<Recipe>) => {
    setIsSubmitting(prev => ({ ...prev, recipes: true }));

    // We automatically connect the recipe to the current pack
    const response = await dispatch(createRecipe({ ...data, pack: pack?.id }));

    const { payload } = response;

    let toastDetails: UseToastOptions = {
      title: 'Recipe created and added to Pack',
      status: 'success',
      duration: 2000,
      isClosable: true,
    };

    if (response.error) {
      const description =
        payload && 'code' in payload && payload.code
          ? payload.code[0]
          : 'The recipe could not be added';

      toastDetails = {
        ...toastDetails,
        title: 'An error occurred',
        status: 'error',
        description,
      };
    }

    toast({ ...toastDetails });

    // Add initial step
    if (payload && 'method' in payload && payload.method !== null) {
      await dispatch(
        createMethodStep(payload.method, {
          stepNumber: 1,
          tasks: [],
          title: 'Step 1',
        }),
      );
    }

    setIsSubmitting(prev => ({ ...prev, recipes: false }));

    return response;
  };

  const handleRemoveRecipePack = async (recipeId: number) => {
    setIsSubmitting(prev => ({ ...prev, recipes: true }));

    const response = await dispatch(removePackRecipe(recipeId));

    const toastDetails: UseToastOptions = {
      title: 'Recipe removed from Pack',
      status: 'success',
      duration: 2000,
      isClosable: true,
    };

    if (response.error) {
      const { payload } = response;
      toast({
        ...toastDetails,
        title: 'An error occurred',
        description:
          payload && 'code' in payload
            ? payload?.code?.[0]
            : 'The Recipe could not be removed from the Pack',
      });
    } else {
      toast({
        ...toastDetails,
      });
    }
    setIsSubmitting(prev => ({ ...prev, recipes: false }));

    return response;
  };

  const handleFetchAllRecipeData = async (
    callback: (ingredientData: RecipeIngredient[][], methodData: MethodStep[][]) => any,
  ) => {
    /**
     * Function for asynchronously retrieving all the Pack's Recipe's
     * Recipe Ingredients and Method Steps to build the Exportable PDF and CSV
     */
    const ingredientData: RecipeIngredient[][] = [];
    const methodData: MethodStep[][] = [];

    await Promise.all([
      ...recipes.map(async ({ id }) => {
        return await dispatch(listRecipeIngredients(id));
      }),
    ]).then(values => {
      const errorResponse = values.map(value => value.error).every(v => v);
      if (errorResponse) {
        toast({
          title: 'Recipe Ingredient Error',
          description: 'Could not fetch Recipe Ingredients for building the PDF',
          status: 'error',
          isClosable: true,
        });
      } else {
        // @ts-ignore - TODO
        ingredientData.push(...values.map(value => value.payload));
      }
    });

    await Promise.all([
      ...recipes.map(async ({ method }) => {
        if (method) {
          return await dispatch(listMethodSteps(method));
        }
        return null;
      }),
    ]).then(values => {
      const errorResponse = values.map(value => value?.error).every(v => v);

      if (errorResponse) {
        toast({
          title: 'Method Step Error',
          description: "Could not fetch Recipe's Method Steps for building the PDF",
          status: 'error',
          isClosable: true,
        });
      } else {
        methodData.push(
          // @ts-ignore - TODO
          ...values.filter(f => f && f?.payload).map(v => v && v.payload),
        );
      }
    });

    return callback(ingredientData, methodData);
  };

  const buildPdfData = (
    ingredientData: RecipeIngredient[][],
    methodData: MethodStep[][],
  ): DocumentProps[] => {
    return recipes.map(({ id, title, code, story }, index) => {
      const recipeIngredients = ingredientData[index];
      const methodSteps = methodData[index];

      const primaryIngredients = buildDefaultRecipeIngredients(recipeIngredients, ingredients);
      const primaryMethods = buildDefaultRecipeMethod(methodSteps);

      const methodStepSection = primaryMethods.map(methodStep => ({
        title: methodStep.title || '',
        number: methodStep.stepNumber,
        items: methodStep.tasks.map(task => {
          let item = convertInstructions(task.instructions);

          if (task.timer?.instruction) {
            item = `${item}\r\n\nTimer Instruction: ${task.timer.instruction}`;
          }

          return item;
        }),
      }));

      return {
        packCode: pack.code,
        packStory: pack.story,
        packTitle: pack.name,
        code,
        title,
        recipeStory: story,
        sections: [
          {
            title: 'Ingredients',
            items: primaryIngredients
              .filter(({ metric }) => !!metric)
              .map(
                ({ ingredient, metric }) =>
                  `${(metric as Quantity).quantity} ${(metric as Quantity).unit.pluralAbbrev} ${
                    ingredient ? ingredients[ingredient].name : ''
                  } ${(metric as Quantity).prepInstructions}`,
              ),
          },
          ...methodStepSection,
        ],
      };
    });
  };

  const buildCsvData = (ingredientData: RecipeIngredient[][], methodData: MethodStep[][]) => {
    const data: {
      Recipe: string;
      Text: string;
      'Asset Code': string;
    }[] = [];

    const recipeCodes = ingredientData.map(d => recipes.find(r => r.id === d[0].recipe)?.code);

    methodData.forEach((methodSteps, index) => {
      methodSteps.forEach(methodStep => {
        methodStep.tasks.forEach(task => {
          // Add timer row
          if (task.timer?.instruction) {
            data.push({
              Recipe: recipeCodes[index] || '',
              Text: task.timer.instruction,
              'Asset Code': buildAssetString({
                recipeCode: recipeCodes[index] || '',
                stepNumber: methodStep.stepNumber,
                portion: 2,
                taskNumber: task.taskNumber,
                suffix: '.mp3',
                isTimer: true,
                isEnd: true,
              }),
            });
          }

          // Convert timer duration from seconds to minutes
          const duration = task.timer?.duration;
          const timerDuration = duration && Math.floor(duration / 60);

          // Add task row
          data.push({
            Recipe: recipeCodes[index] || '',
            Text: convertInstructions(task.instructions),
            'Asset Code': buildAssetString({
              recipeCode: recipeCodes[index] || '',
              stepNumber: methodStep.stepNumber,
              portion: 2,
              taskNumber: task.taskNumber,
              suffix: '.mp3',
              isTimer: !!task.timer,
              timerDuration,
            }),
          });
        });
      });
    });

    return data;
  };

  if (!pack || !packThemes || isLoading) {
    return (
      <ScreenWrapper>
        <Stack spacing="md">
          <Skeleton height="40px" width="30%" />
          <Skeleton height="20px" width="80%" />
          <Skeleton height="20px" width="80%" />
        </Stack>
      </ScreenWrapper>
    );
  }

  // const equipmentListData = shoppingListData?.equipment
  //   ? buildEquipmentData(
  //       shoppingListData.equipment,
  //       portionType,
  //       pack.primaryPortion,
  //       pack.secondaryPortion,
  //     ).sort((a, b) => (a.ingredient > b.ingredient ? 1 : -1))
  //   : [];

  return (
    <ScreenWrapper>
      <Prompt when={isDirty} message="You have unsaved Changes. Are you sure you want to leave?" />
      <Stack spacing="md" divider={<StackDivider borderColor="gray.200" />}>
        <PackDetailsForm
          pack={pack}
          itemCounts={{
            primaryIngredients: 0,
            primaryEquipment: 0,
          }}
          onSubmit={handlePackDetailsSubmit}
          isSubmitting={isSubmitting.primary}
          isGeneratingShoppingList={false}
          shoppingListExists
        />
        <PackAdditionalDetailsForm
          pack={pack}
          onSubmit={handlePackSecondaryFormSubmit}
          themes={Object.values(packThemes)
            .filter(tag => !ingredientTags.includes(tag.name))
            .map(tag => tag.name)}
          isSubmitting={isSubmitting.secondary}
        />
        <PackRecipesForm
          recipes={recipes}
          addRecipe={handleAddRecipePack}
          removeRecipe={handleRemoveRecipePack}
          onRecipeClick={recipeId => navigate(`${routes.recipesList}/${recipeId}`)}
          isSubmitting={isSubmitting.recipes}
        />
        <Flex flexDirection="column">
          <ShoppingList data={shoppingListData} />
        </Flex>
        {/* TODO : Fix this */}
        {/* <Flex flexDirection="column">
          <Heading as="h3" size="lg" mb="sm">
            Equipment List
          </Heading>

          {equipmentListData.length > 0 ? (
            <EquipmentList data={equipmentListData} />
          ) : (
            <Text size="md">This pack does not have an equipment list</Text>
          )}
        </Flex> */}

        <Flex flexDirection="column">
          <Text fontWeight="bold">Meal Pack Actions:</Text>
          <HStack mt="sm" spacing="sm">
            <CSVLink
              data={csvData}
              ref={csvRef}
              filename={`${pack.code}_timer_instructions_all_recipes.csv`}
            />
            <Button
              isLoading={isCsvDataLoading}
              onClick={async () => {
                setIsCsvDataLoading(true);
                const data = await handleFetchAllRecipeData(buildCsvData);
                setCsvData(data);
                setIsCsvDataLoading(false);
              }}
            >
              Export Recipes CSV
            </Button>
            <Button
              colorScheme="blue"
              onClick={async () => {
                setIsPdfDataLoading(true);
                const data = await handleFetchAllRecipeData(buildPdfData);
                setPdfData(data);
                setIsPdfOpen(true);
                setIsPdfDataLoading(false);
              }}
              isLoading={isPdfDataLoading}
            >
              Export Recipes PDF
            </Button>
            <Button colorScheme="orange" onClick={() => setShowDuplicateModal(true)}>
              Duplicate Pack
            </Button>
          </HStack>
        </Flex>
      </Stack>
      {isPdfOpen && pdfData && (
        <PDFModalMultiple
          isOpen={isPdfOpen}
          onClose={() => setIsPdfOpen(false)}
          pdfData={pdfData}
          packCode={pack.code}
        />
      )}
      <Modal isOpen={showDuplicateModal} onClose={() => setShowDuplicateModal(false)} size="lg">
        <ModalOverlay />
        <ModalContent>
          <form
            onSubmit={handleSubmit(async ({ newCode }) => {
              if (!newCode) return;
              setIsSubmitting(prev => ({ ...prev, newPack: true }));
              const res = await dispatch(duplicatePack(pack.id, { newCode }));
              toast({
                title: res.error
                  ? 'Failed to duplicate pack'
                  : `New pack ${newCode} succesfully created`,
                description: res.error && res.payload?.message,
                isClosable: true,
                status: res.error ? 'error' : 'success',
                duration: 2000,
              });
              setIsSubmitting(prev => ({ ...prev, newPack: false }));
            })}
          >
            <ModalHeader>Duplicate Meal Pack</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex>
                <LabelInput
                  placeholder="New Pack Code"
                  name="newCode"
                  register={register}
                  registerOptions={{
                    required: true,
                  }}
                  label="New Pack Code"
                  errorText={
                    errors.newCode ? 'Please enter a pack code for the duplicate' : undefined
                  }
                  isInvalid={Boolean(errors.newCode)}
                  isRequired
                />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button
                  type="submit"
                  isLoading={isSubmitting.newPack}
                  isDisabled={!isDirty || isSubmitting.newPack}
                  colorScheme="blue"
                >
                  Duplicate Pack
                </Button>
                <Button
                  onClick={() => setShowDuplicateModal(false)}
                  isDisabled={isSubmitting.newPack}
                >
                  Close
                </Button>
              </HStack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </ScreenWrapper>
  );
};

export default MealPackScreen;
