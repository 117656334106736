interface Props {
  recipeCode: string;
  stepNumber: number;
  portion?: number;
  taskNumber?: number;
  suffix: string;
  isTimer?: boolean;
  isEnd?: boolean;
  timerDuration?: number;
}

const buildAssetString = ({
  recipeCode,
  stepNumber,
  portion,
  taskNumber,
  suffix,
  isTimer,
  isEnd,
  timerDuration,
}: Props) => {
  /**
   * Builds the asset string
   * e.g. R015-M&F_S4_4P_A1.mp3
   * [RECIPE_CODE - STEP_NUMBER - PORTION - TASK_NUMBER]
   */

  const base = `${recipeCode}_S${stepNumber}`;

  if (!portion) {
    return `${base}${suffix}`;
  }

  if (!isTimer) {
    return `${base}_${portion}P_A${taskNumber}${suffix}`;
  }

  return isEnd
    ? `${base}_${portion}P_A${taskNumber}_T${stepNumber}_END${suffix}`
    : `${base}_${portion}P_A${taskNumber}_T${stepNumber}${
        timerDuration ? `_${timerDuration}` : ''
      }${suffix}`;
};

export default buildAssetString;
